import React from "react"
import Layout from "../../layouts/default"

import AudioPlayer from "../../components/audio-player"
import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Small from "../../components/small"
import Stack from "../../components/stack"

const Katalog25 = () => {
  return (
    <Layout backdrop="aussen">
      <Seo title="Heinz Holliger: Die Jahreszeiten für Chor a-capella (Winter)" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title:
                  "Heinz Holliger: Die Jahreszeiten für Chor a-capella (Winter)",
                link: "/k/25",
              },
            ]}
          />
          <PageTitle>
            Heinz Holliger: Die Jahreszeiten für Chor a-capella (Winter)
          </PageTitle>
          <Constrain>
            <Stack space={6}>
              <AudioPlayer src="katalog/25-heinz-holliger-jahreszeiten-winter" />
              <Small color="whisper">
                Mit freundlicher Genehmigung durch ECM Records
              </Small>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog25
